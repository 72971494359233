/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Container } from "../Styles/Layout/Container";
// import CustomerInquiryForm from "../Components/CustomerInquiryForm";
import CustomerServiceFormStepOne from "../Components/CustomerServiceFormStepOne";
import CustomerServiceFormStepTwo from "../Components/CustomerServiceFormStepTwo";
import CustomerServiceFormStepThree from "../Components/CustomerServiceFormStepThree";
import CustomerServiceFormSuccess from "../Components/CustomerServiceFromSuccess";
import CustomerServiceVideoForm from "../Components/CustomerServiceVideoForm";
import ScheduledServiceSelector from "../Components/ScheduledServiceSelector";
import jobsAPI from "../ClientAPI/jobsAPI";
import { formatPhoneNumber } from "../service/helper";
import "./CustomerInquiryPage.css";
// import { GLOSS_BOSS_SHOP_ID } from "../constants";

export enum PageType {
  Inquiry,
  ScheduledService,
}

export type UserInfo = {
  customerPhone: string;
  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
  carModel: string;
  service: string;
  note?: string;
  scheduledAt?: string;
  scheduledAtAsLocal?: string;
};

interface ICustomerInquiryPageProps {
  queryVal: string;
  type: PageType;
}

const CustomerInquiryPage = ({ queryVal, type }: ICustomerInquiryPageProps) => {
  const [loaded, setLoaded] = useState(false);
  const [shopName, setShopName] = useState("");
  const [shopAddress, setShopAddress] = useState("");
  const [shopId, setShopId] = useState("");
  const [shopPhone, setShopPhone] = useState("");
  const [shopScheduledData, setShopScheduledData] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [isSchedulePreview, setIsSchedulePreview] = useState(false);
  const [viewStep, setViewStep] = useState<number>(1);
  const [videoUrl, setVideoUrl] = useState<string>("");

  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [isApiLoading, setIsApiLoading] = useState(false);

  useEffect(() => {
    const keys: string[] = queryVal.split("pre_");
    let key = "";
    if (keys.length === 2) {
      key = keys[1];
      setIsSchedulePreview(true);
    } else {
      key = queryVal;
    }
    jobsAPI
      .validateToken(key)
      .then((data) => {
        console.log("shop info===", data);
        if (data.status === "submitted") {
          setSubmitted(true);
        }
        setShopName(data.businessName || "");
        setShopId(data.shopID);
        setShopAddress(data.shopData.businessAddress || "");
        setShopPhone(data.shopData.shopTelephone || "");
        setShopScheduledData(data.shopData.scheduledData || null);
        setSubmitted(data.status === "submitted");
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });
  }, []);

  const submitInquiry = async (payload: any) => {
    try {
      const response =
        type === PageType.Inquiry
          ? await jobsAPI.createNewInquiry(payload, queryVal)
          : await jobsAPI.createNewScheduleInquiry(payload, queryVal);
      if (response.status === 201) {
        // setSubmitted(true);
        setIsApiLoading(false);
      }
    } catch (err: any) {
      window.alert(err.message);
      setIsApiLoading(false);
    }
  };

  const changeViewStep = async (
    step: number,
    videoUrl?: string,
    scheduledAt?: string,
    scheduledAtAsLocal?: string
  ) => {
    if (videoUrl) {
      setVideoUrl(videoUrl);
      setIsApiLoading(true);
      await submitInquiry({ ...userInfo, videoUrl });
    }
    if (scheduledAt && scheduledAtAsLocal && userInfo) {
      setUserInfo({ ...userInfo, scheduledAt, scheduledAtAsLocal });
    }
    setViewStep(step);
  };

  const handleUserInfo = (userInfo: UserInfo) => {
    setUserInfo(userInfo);
  };

  return (
    <>
      {submitted ? (
        <Container>
          <p className="font-big-center">
            <b>Successfully Submitted</b> <br />
            <br />
            We'll reply to further assist with your vehicle service shortly 🚗💨
          </p>
        </Container>
      ) : loaded ? (
        shopName ? (
          // <CustomerInquiryForm
          // shopAddress={shopAddress}
          // shopName={shopName}
          // shopPhone={shopPhone}
          //   scheduledData={shopScheduledData}
          //   pageType={type}
          //   onSubmit={submitInquiry}
          //   isSchedulePreview={isSchedulePreview}
          //   isShowMainLogo={shopId !== GLOSS_BOSS_SHOP_ID}
          // />
          <>
            {viewStep === 1 && (
              <CustomerServiceFormStepOne
                shopName={shopName || "Urban Vinyl"}
                onNext={changeViewStep}
                isSchedule={type === PageType.ScheduledService}
              />
            )}
            {viewStep === 2 && (
              <CustomerServiceFormStepTwo
                shopName={shopName || "Urban Vinyl"}
                shopAddress={shopAddress}
                shopPhone={shopPhone}
                pageType={type}
                onSubmit={handleUserInfo}
                onNext={changeViewStep}
              />
            )}
            {viewStep === 3 && (
              <CustomerServiceFormStepThree onNext={changeViewStep} />
            )}
            {viewStep === 4 && (
              <CustomerServiceVideoForm
                onNext={changeViewStep}
                userName={`${userInfo?.customerFirstName}_${userInfo?.customerLastName}`}
                isApiLoading={isApiLoading}
              />
            )}
            {viewStep === 5 && (
              <CustomerServiceFormSuccess
                shopName={shopName || "Urban Vinyl"}
                shopAddress={shopAddress}
                customerName={
                  `${userInfo?.customerFirstName} ${userInfo?.customerLastName}` ||
                  ""
                }
                title1="You have successfully prepared us to personalize your experience at"
                title2="We recieved your Service Request and currently reviewing your vehicle."
              />
            )}
            {viewStep === 6 && (
              <ScheduledServiceSelector
                shopName={shopName || "Urban Vinyl"}
                shopAddress={shopAddress}
                onNext={changeViewStep}
              />
            )}
          </>
        ) : (
          <Container>
            <h2 className="font-big-center">
              Oops…page expired, text shop again to receive new form <br />
              <br />
              {shopPhone ? <p>Text : {formatPhoneNumber(shopPhone)} </p> : ""}
            </h2>
          </Container>
        )
      ) : (
        <Container>
          <h1 className="main-header">Loading...</h1>
        </Container>
      )}
    </>
  );
};

export default CustomerInquiryPage;
