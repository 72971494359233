import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import ScheduleSlide1 from "../assets/schedule_1.png";
import ScheduleSlide2 from "../assets/schedule_2.png";
import ScheduleSlide3 from "../assets/schedule_3.png";
import InquirySlide1 from "../assets/inquiry_1.png";
import InquirySlide2 from "../assets/inquiry_2.png";
import InquirySlide3 from "../assets/inquiry_3.png";
import { Row } from "../Styles/Layout/Row";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useCallback } from "react";
import { DotButton, useDotButton } from "./EmblaCarouselDotButton";

type Props = {
  shopName: string;
  onNext: (nextStep: number) => void;
  isInquiry?: boolean;
  isSchedule?: boolean;
};

export default function CustomerServiceFormStepOne({
  shopName,
  onNext,
  isInquiry = true,
  isSchedule = false,
}: Props) {
  const getPageTitle = useCallback(() => {
    if (isInquiry) {
      if (isSchedule) {
        return (
          <span>
            Let's schedule your upcoming vehicle service here at {shopName}
          </span>
        );
      }
      return (
        <>{shopName} is requesting a better look at your vehicle using video</>
      );
    }
    return <>{shopName} is preparing your vehicle for pickup</>;
  }, [isInquiry, isSchedule, shopName]);

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false }, [
    Autoplay({ delay: 5000 }),
  ]);

  const slides = isSchedule
    ? [ScheduleSlide1, ScheduleSlide2, ScheduleSlide3]
    : [InquirySlide1, InquirySlide2, InquirySlide3];
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  return (
    <Box my={4} py={2}>
      <Row justifyContent="center" padding="1rem">
        <Typography
          variant="h6"
          mt={3}
          sx={{ color: "#1B34CE", fontWeight: "bold" }}
        >
          {getPageTitle()}
        </Typography>
      </Row>
      {!isInquiry && (
        <Row justifyContent="center" padding="1rem">
          <Typography
            variant="h6"
            sx={{ color: "#1B34CE", fontWeight: "bold" }}
          >
            Preview your vehicle and confirm us at your earliest convenience
            🚗💨
          </Typography>
        </Row>
      )}
      <Box sx={{ position: "relative", mt: "1rem" }}>
        <div className="embla__controls">
          <div className="embla__dots">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={"embla__dot".concat(
                  index === selectedIndex ? " embla__dot--selected" : ""
                )}
              />
            ))}
          </div>
        </div>
        <div className="embla" ref={emblaRef}>
          <div className="embla__container">
            {isInquiry &&
              slides.map((src, index) => (
                <div className="embla__slide" key={index}>
                  <img
                    src={src}
                    alt="back"
                    width="100%"
                    style={{ height: "20rem" }}
                  />
                </div>
              ))}
          </div>
        </div>
      </Box>
      <Button
        variant="contained"
        sx={{
          textTransform: "none",
          bgcolor: "#1B34CE",
          position: "absolute",
          right: "1rem",
          bottom: "2rem",
        }}
        onClick={() => onNext(2)}
      >
        Let's Go! 🏁
      </Button>
    </Box>
  );
}
